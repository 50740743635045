import React from "react";
import "./App.css";
// import { Navbar, Slideshow, Products, Production, Footer } from "./components";
import { Route, Routes } from "react-router-dom";
import LanguagePage from "./pages/LanguagePage";
import ScrollToTop from "./components/ScrollToTop"; // Import the ScrollToTop component

import HomePage from "./pages/en/HomePage";
import ProductPage from "./pages/en/ProductPage";
import MilkyPage from "./pages/en/MilkyPage";
import ServicesPage from "./pages/en/ServicesPage";
import AboutPage from "./pages/en/AboutPage";
import ContactPage from "./pages/en/ContactPage";
import ClientsPage from "./pages/en/ClientsPage";

import HomePageFr from "./pages/fr/HomePageFr";
import ProductPageFr from "./pages/fr/ProductPageFr";
import MilkyPageFr from "./pages/fr/MilkyPageFr";
import ServicesPageFr from "./pages/fr/ServicesPageFr";
import AboutPageFr from "./pages/fr/AboutPageFr";
import ContactPageFr from "./pages/fr/ContactPageFr";
import ClientPageFr from "./pages/fr/ClientPageFr";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* Landing Page */}
        <Route path="/" element={<LanguagePage />} />

        {/* English Routes */}
        <Route path="/en/home" element={<HomePage />} />
        <Route path="/en/products" element={<ProductPage />} />
        <Route path="/en/partners" element={<ClientsPage />} />
        <Route path="/en/products/milky" element={<MilkyPage />} />
        <Route path="/en/products/bladna" element={<MilkyPage />} />
        <Route path="/en/products/almarai" element={<MilkyPage />} />
        <Route path="/en/products/cheeseland" element={<MilkyPage />} />
        <Route path="/en/products/fromy" element={<MilkyPage />} />
        <Route path="/en/products/labonnevache" element={<MilkyPage />} />
        <Route path="/en/products/almaraipremium" element={<MilkyPage />} />
        <Route path="/en/products/lahda" element={<MilkyPage />} />
        <Route path="/en/products/vitacheese" element={<MilkyPage />} />
        <Route path="/en/services" element={<ServicesPage />} />
        <Route path="/en/about" element={<AboutPage />} />
        <Route path="/en/contact" element={<ContactPage />} />

        {/* French Routes */}
        <Route path="/fr/home" element={<HomePageFr />} />
        <Route path="/fr/products" element={<ProductPageFr />} />
        <Route path="/fr/partners" element={<ClientPageFr />} />
        <Route path="/fr/products/milky" element={<MilkyPageFr />} />
        <Route path="/fr/products/bladna" element={<MilkyPageFr />} />
        <Route path="/fr/products/almarai" element={<MilkyPageFr />} />
        <Route path="/fr/products/cheeseland" element={<MilkyPageFr />} />
        <Route path="/fr/products/fromy" element={<MilkyPageFr />} />
        <Route path="/fr/products/labonnevache" element={<MilkyPageFr />} />
        <Route path="/fr/products/almaraipremium" element={<MilkyPageFr />} />
        <Route path="/fr/products/lahda" element={<MilkyPageFr />} />
        <Route path="/fr/products/vitacheese" element={<MilkyPageFr />} />
        <Route path="/fr/services" element={<ServicesPageFr />} />
        <Route path="/fr/about" element={<AboutPageFr />} />
        <Route path="/fr/contact" element={<ContactPageFr />} />
      </Routes>
    </>
  );
}

export default App;
