import React from "react";
import { Link } from "react-router-dom";
import "./events.css";
import sial from "../../assets/eventspics/sial.jpg";
import earthquake from "../../assets/eventspics/earthquake.webp";

const EventCard = (props) => {
  return (
    <div key={props.index} className="events-item-event-container">
      <img src={props.event.link} alt={props.event.name} />
      <div className="events-item-protuct-text">
        <Link to={props.event.sendtolink}>
          <p className="event-name">{props.event.name}</p>
        </Link>
        <div className="event-date">
          <p>Date : {props.event.date}</p>
        </div>
        <p>{props.event.description}</p>
        <p className="event-category">{props.event.category}</p>
      </div>
    </div>
  );
};

const Events = () => {
  const eventList = {
    events: [
      {
        name: "GOLD CHEESE MOROCCO is participating in the SIAL Paris 2024",
        id: "sial2024",
        date: "07-10-2024",
        description:
          "GOLD CHEESE MOROCCO is excited to announce its participation in SIAL Paris 2024, one of the most prestigious international food exhibitions. This event provides a unique platform for the company to showcase its premium cheese events to a global audience. Attendees will have the opportunity to taste our distinctive flavors and learn about our commitment to quality and innovation. We look forward to connecting with industry professionals, potential partners, and food enthusiasts from MENA and african countries. Join us at SIAL Paris to discover the rich culinary heritage of Moroccan cheese!",
        link: sial,
        sendtolink:
          "https://www.linkedin.com/feed/update/urn:li:activity:7251620955347648515/",
      },
      {
        name: "GOLD CHEESE MOROCCO helps with the Earthquake.",
        id: "earthquake2023",
        date: "23-09-2023",
        description:
          "In response to the devastating earthquake that hit Morocco in 2023, Gold Cheese Morocco took meaningful action by providing much-needed food supplies to affected communities. Recognizing the importance of nutrition during challenging times, our team quickly mobilized to offer our products to those in need. While our contribution may have seemed modest, it was part of a broader effort to support recovery and uplift spirits. We are proud to stand with our fellow Moroccans during this difficult time and remain committed to contributing positively to our community.",
        link: earthquake,
      },
    ],
  };
  return (
    <div className="events section__padding section">
      <div className="events-title-container">
        <h1 className="events-title">Events</h1>
      </div>
      {/* Events*/}
      <div className="events-item-container">
        {/* <h1>Events : </h1> <br /> */}
        {eventList.events.map((event, index) => (
          <EventCard event={event} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Events;
