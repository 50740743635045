import React from "react";
// import { Link } from "react-router-dom";
import "./products.css";
import milky from "../../assets/product/milky-nobg.png";
import bladna from "../../assets/product/bladna-nobg.png";
import labonnevache from "../../assets/product/labonnevache-nobg.png";
import marai from "../../assets/product/marai-nobg.png";
import maraipremium from "../../assets/product/maraipremium-nobg.png";
import fromy from "../../assets/product/fromy-nobg.png";
import cheeseland from "../../assets/product/cheeseland-nobg.png";
import vitacheese from "../../assets/product/vitacheese-nobg.png";
import bladna2 from "../../assets/product/bladna3-nobg.png";
import bladna3 from "../../assets/product/bladna4-nobg.png";
import milkycream from "../../assets/product/milkycreamcheese-nobg.png";
import milkycream2 from "../../assets/product/milkycreamcheese2-nobg.png";

const ProductCard = (props) => {
  return (
    <div key={props.index} className="products-item-product-container">
      <img src={props.product.link} alt={props.product.name} />
      <div className="products-item-product-text">
        <p className="product-name">{props.product.name}</p>
        <p>{props.product.description}</p>
        {/* <p className="product-category">{props.product.category}</p> */}
      </div>
    </div>
  );
};

const Products = () => {
  const productslist = {
    processed: [
      {
        name: "Milky Cheese",
        id: "milky",
        category: "Our Own Brand",
        description:
          "The triangular portion MILKY cheese is a comforting delight, often associated with moments of sharing and conviviality. Its creamy texture and savory flavor make it a perfect ingredient to enhance dishes or simply enjoy on its own. The MILKY portions are ideal for appetizers, melting wonderfully in dishes like fondues, gratins, or even as a side with crunchy vegetables. Their triangular shape also makes them easy to consume, whether at home or during a picnic.",
        link: milky,
        price: 0,
      },
      {
        name: "Bladna Cheese",
        id: "bladna",
        category: "Our Own Brand",
        description:
          "The BLADNA cheese is a true culinary treasure, emblematic of the country's gastronomy. Known for its rich flavor and creamy texture, it is often made from cow's milk. Its traditional production method highlights artisanal craftsmanship, preserving the authentic flavors of the region. Baladna stands out for its perfect balance between salty and sweet, offering a unique tasting experience. It can be enjoyed on its own as an appetizer or incorporated into various local dishes, adding a savory touch to salads, sandwiches, or cooked meals.",
        link: bladna,
        price: 0,
      },
      {
        name: "Al Marai Shahya Cheese",
        id: "marai",
        category: "Our Own Brand",
        description:
          "Almarai El Shahya cheese embodies the perfect blend of tradition and innovation. Crafted with ancestral expertise, every step of its production is carefully orchestrated to ensure exceptional quality. Almarai dairies use modern techniques to guarantee complete traceability and optimal freshness, preserving the richness of flavors. The high technology employed in the manufacturing process not only meets the strictest food safety standards but also optimizes processes for increased efficiency.",
        link: marai,
        price: 0,
      },
      {
        name: "Al Marai Premium Cheese",
        id: "maraipremium",
        category: "Our Own Brand",
        description:
          "Almarai El Shahya cheese stands out for its creamy texture and refined flavor, making each bite a unique tasting experience. Whether accompanying a dish or enjoyed on its own, it fits perfectly into a variety of cuisines, pleasing even the most discerning palates. In short, Almarai El Shahya is not just a cheese; it is the result of a passion for excellence and a constant pursuit of innovation.",
        link: maraipremium,
        price: 0,
      },
    ],
    mdd: [
      {
        name: "La Bonne Vache",
        id: "labonnevache",
        category: "Private Label",
        description: "",
        link: labonnevache,
        price: 0,
      },
      {
        name: "Fromy Cheese",
        id: "fromy",
        category: "Private Label",
        description: "",
        link: fromy,
        price: 0,
      },
      {
        name: "CheeseLand Cheese",
        id: "cheeseland",
        category: "Private Label",
        description: "",
        link: cheeseland,
        price: 0,
      },
      {
        name: "Vita Cheese",
        id: "vitacheese",
        category: "Private Label",
        description: "",
        link: vitacheese,
        price: 0,
      },
    ],
    spreadables: [
      {
        name: "Milky Cream Cheese 2Kg",
        id: "milkycreamcheese",
        category: "Our Own Brand",
        description:
          "Enjoy the rich, creamy taste of Milky Cream Cheese! Perfect for spreading on bagels, baking delicious cheesecakes, or making tasty dips. Our 2kg tub is convenient for home use or catering. Elevate your meals with Milky Cream Cheese today!",
        link: milkycream,
        price: 0,
      },
      {
        name: "Milky Cream Cheese 250g",
        id: "milkycreamcheese2",
        category: "Our Own Brand",
        description:
          "Discover the delicious taste of Milky Cream Cheese in a convenient 250g size! Ideal for spreading on toast, adding to recipes, or enjoying straight from the tub. Perfect for smaller meals and snacks, Milky Cream Cheese is your go-to for creamy goodness anytime!",
        link: milkycream2,
        price: 0,
      },
      {
        name: "Bladna",
        id: "bladna2",
        category: "Our Own Brand",
        description:
          "Indulge in the smooth and creamy goodness of Bladna Cream Cheese! Perfectly balanced in flavor, it’s ideal for spreading on your favorite bread, enhancing recipes, or creating delightful dips. Available in a convenient size, Bladna brings a touch of richness to every meal. Elevate your snacking and cooking with Bladna Cream Cheese!",
        link: bladna2,
        price: 0,
      },
    ],
    pizzacheese: [
      {
        name: "Bladna",
        id: "bladna3",
        category: "Our Own Brand",
        description:
          "Introducing Bladna, the ultimate cheese block for pizza lovers! Crafted from the finest ingredients, Bladna delivers a rich, creamy texture that melts beautifully, ensuring every slice is a cheesy delight. Perfect for traditional pies or innovative toppings, this versatile cheese block enhances the flavor profile of any pizza, creating a mouthwatering experience that will have you coming back for more. Elevate your pizza night with Bladna—where quality meets indulgence!",
        link: bladna3,
        price: 0,
      },
    ],
  };
  return (
    <div className="products section__padding section">
      <div className="products-title-container">
        <h1 className="products-title">Products</h1>
      </div>
      {/* Produit Tartine */}
      <div className="products-item-container">
        <h1>Processed Triangle Cheese</h1> <br />
        <h1>Our Own Brands</h1>
        {productslist.processed.map((product, index) => (
          // <Link to={"/en/products/" + product.id}>
          <ProductCard product={product} index={index} />
          // </Link>
        ))}
        <h1>Private Labels - MDD</h1>
        {productslist.mdd.map((product, index) => (
          // <Link to={"/en/products/" + product.id}>
          <ProductCard product={product} index={index} />
          // </Link>
        ))}
        <h1>Spreadable and Cream Cheese</h1> <br />
        {productslist.spreadables.map((product, index) => (
          // <Link to={"/en/products/" + product.id}>
          <ProductCard product={product} index={index} />
          // </Link>
        ))}
        <h1>Block Cheese</h1> <br />
        {productslist.pizzacheese.map((product, index) => (
          // <Link to={"/en/products/" + product.id}>
          <ProductCard product={product} index={index} />
          // </Link>
        ))}
      </div>
    </div>
  );
};

export default Products;
