import React from "react";
import "../../index.css";
import {
  Navbar,
  Slideshow,
  Footer,
  Products,
  Production,
  Events,
} from "../../components";

const HomePage = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar className="navbar" language="en" />
        <Slideshow />
        <Events />
        <Production />
        <Products />
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
