import React from "react";
import "../../index.css";
import { Navbar, Slideshow, Footer, Clients } from "../../components";

const ClientsPage = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar className="navbar" language="en" />
        <Slideshow />
        <Clients />
        <Footer />
      </div>
    </div>
  );
};

export default ClientsPage;
