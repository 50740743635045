import React from "react";
import "../../index.css";
import {
  NavbarFr,
  FooterFr,
  ProductsFr,
  ProductionFr,
  EventsFr,
} from "../../componentsFr";
import { Slideshow } from "../../components";

const HomePageFr = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <NavbarFr className="navbar" language="fr" />
        <Slideshow />
        <EventsFr />
        <ProductionFr />
        <ProductsFr />
        <FooterFr />
      </div>
    </div>
  );
};

export default HomePageFr;
