import React, { useState, useCallback } from "react";
import "./slideshow.css";
import slide from "../../assets/slides/04.jpg";
import slide1 from "../../assets/slides/01.jpg";
import slide2 from "../../assets/slides/02.jpg";
import slide3 from "../../assets/slides/03.jpg";
import slide4 from "../../assets/slides/05.jpg";

const Slideshow = () => {
  const images = [slide, slide1, slide2, slide3, slide4];
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  }, [images.length]);

  // useEffect(() => {
  //   const interval = setInterval(goToNextSlide, 5000);
  //   return () => clearInterval(interval);
  // }, [goToNextSlide]);

  return (
    <div className="gcm-slideshow-container">
      <button
        className="prev"
        onClick={goToPrevSlide}
        aria-label="Previous slide"
      >
        &#10094;
      </button>
      <button className="next" onClick={goToNextSlide} aria-label="Next slide">
        &#10095;
      </button>
      <div className="slideshow-slide">
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="slide-picture"
        />
      </div>
      <div className="dot-container">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
            role="button"
            aria-label={`Slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
