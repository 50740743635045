import React from "react";
import "../../index.css";
import { NavbarFr, FooterFr, ClientsFr } from "../../componentsFr";
import { Slideshow } from "../../components";

const ClientPageFr = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <NavbarFr className="navbar" language="fr" />
        <Slideshow />
        <ClientsFr />
        <FooterFr />
      </div>
    </div>
  );
};

export default ClientPageFr;
