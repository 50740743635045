import React, { useRef, useState } from "react";
import "./contact.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const [message, setMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_0fc6mir", "template_5jo1a06", form.current, {
        publicKey: "rgW3jNRAKZwmc16rI",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setMessage("Email sent successfully!");
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          setMessage("Failed to send email. Please try again later.");
        }
      );
  };

  return (
    <div className="contact-container section__padding">
      <h1>Careers</h1>
      <br />
      <p>
        If you are interested in joining the Gold Cheese Morocco team, send your
        CV to <b>info@goldcheesemorocco.com</b>
      </p>
      <br />
      <h3>Contact Us</h3>
      <br />
      <p>
        For more information, please contact us by calling{" "}
        <b>+212 5 22 96 14 71</b> or by email <b>info@goldcheesemorocco.com</b>{" "}
        or by filling the form below:
      </p>
      <div className="form section__padding">
        {message && <p className="contact-message">{message}</p>}{" "}
        {/* Display message if it exists */}
        <div className="contact-container">
          <form className="form-submit" ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea className="form-message" name="message" />
            <button className="form-send" type="submit" value="Send">
              <p>Send</p>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
