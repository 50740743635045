import React, { useRef, useState } from "react";
import "../../components/contact/contact.css";
import emailjs from "@emailjs/browser";

const ContactFr = () => {
  const form = useRef();
  const [message, setMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_0fc6mir", "template_5jo1a06", form.current, {
        publicKey: "rgW3jNRAKZwmc16rI",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setMessage("Email sent successfully!");
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          setMessage("Failed to send email. Please try again later.");
        }
      );
  };

  return (
    <div className="contact-container section__padding">
      <h1>Carrières</h1>
      <br />
      <p>
        Si vous êtes intéressé à rejoindre l'équipe de Gold Cheese Morocco,
        envoyez votre CV à: <b>info@goldcheesemorocco.com</b>
      </p>
      <br />
      <h3>Contactez Nous</h3>
      <br />
      <p>
        Pour plus d'informations, veuillez nous contacter en appelant:{" "}
        <b>+212 5 22 96 14 71</b> ou par email :{" "}
        <b>info@goldcheesemorocco.com</b> ou en remplissant le formulaire
        ci-dessous :
      </p>
      <div className="form section__padding">
        {message && <p className="contact-message">{message}</p>}{" "}
        {/* Display message if it exists */}
        <div className="contact-container">
          <form className="form-submit" ref={form} onSubmit={sendEmail}>
            <label>Nom</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea className="form-message" name="message" />
            <button className="form-send" type="submit" value="Send">
              <p>Envoyer</p>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactFr;
