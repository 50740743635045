import React from "react";
import "./productinfo.css";

const ProductInfo = (props) => {
  return (
    <div key={props.index} className="products-item-product-container">
      <img src={props.product.link} alt={props.product.name} />
      <div className="products-item-protuct-text">
        <p className="product-name">{props.product.name}</p>
        <p>{props.product.description}</p>
        <p className="product-category">{props.product.category}</p>
      </div>
    </div>
  );
};

export default ProductInfo;
