import React, { useEffect, useState } from "react";
import "./logoslideshow.css"; // Import your CSS for styling

import topfood from "../../assets/partners/topfoodproductslogo.png";
import agrofood from "../../assets/partners/agrofood.png";
import fgd from "../../assets/partners/fgd.png";
import fromagerieatlas from "../../assets/partners/fromagerie-atlas.png";
import gastromixte from "../../assets/partners/gastromixte.png";
import partners from "../../assets/partners/partners.png";
import somathes from "../../assets/partners/somathes.png";
import dindy from "../../assets/partners/dindy.png";
import sbd from "../../assets/partners/sbd.jpeg";

const locallogos = [
  topfood,
  partners,
  fgd,
  sbd,
  dindy,
  somathes,
  gastromixte,
  agrofood,
  fromagerieatlas,
];

const overseaslogos = [];

const LogoSlideshow = ({ interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const updateIndex = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % Math.ceil(locallogos.length / 3)
    );
  };

  useEffect(() => {
    const timer = setInterval(updateIndex, interval);
    return () => clearInterval(timer);
  }, [interval]);

  const totalSlides = Math.ceil(locallogos.length / 3);
  const containerWidth = `${totalSlides * 100}%`;

  return (
    <div className="logo-slideshow">
      <p>Local Clients</p>
      <br />
      <div
        className="logo-container"
        style={{
          transform: `translateX(-${(currentIndex * 100) / totalSlides}%)`,
          width: containerWidth,
        }}
      >
        {locallogos.map((logo, index) => (
          <div key={index} className="logo">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))}
      </div>
      <p>Overseas Clients</p>
      <br />
      <div
        className="logo-container"
        style={{
          transform: `translateX(-${(currentIndex * 100) / totalSlides}%)`,
          width: containerWidth,
        }}
      >
        {overseaslogos.map((logo, index) => (
          <div key={index} className="logo">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoSlideshow;
