import React from "react";
import "./production.css";
import factory from "../../assets/misc/factory.jpg";
import factory1 from "../../assets/misc/5.jpg";
import factory2 from "../../assets/misc/6.jpg";

const Production = () => {
  return (
    <div className="production-container section__padding">
      <h1>Production Quality</h1>
      <div className="production-image-container">
        <img
          className="production-image"
          src={factory}
          alt="Factory of Production"
        />
        <img
          className="production-image"
          src={factory1}
          alt="Factory of Production"
        />
        <img
          className="production-image"
          src={factory2}
          alt="Factory of Production"
        />
      </div>
      <div className="production-text-container">
        <p>
          Welcome to Gold Cheese Morocco.
          <br />
          At Gold Cheese Morocco, we are passionate about bringing you the
          finest processed cheese, crafted with care and precision in our
          state-of-the-art factory. Our commitment to quality and excellence is
          evident in every step of our manufacturing process, ensuring that you
          enjoy a product that stands out for its taste, texture, and freshness.
          <br />
          Our Factory located in the heart of Morocco is equipped with the
          latest technology and adheres to the highest international standards.
          From the moment fresh milk arrives at our facility, it undergoes
          rigorous quality checks to ensure it meets our stringent criteria. Our
          production lines are designed to optimize efficiency while maintaining
          the artisanal touch that defines our cheese. Quality Assurance At Gold
          Cheese Morocco, quality is our top priority. Our dedicated team of
          experts oversees every stage of production to ensure that our cheese
          not only meets but exceeds industry standards. We adhere to strict
          hygiene protocols and continuously monitor our processes to maintain
          the highest level of excellence.
          <br />
          <br /> Experience the superior taste and quality of Gold Cheese
          Morocco—where tradition meets innovation in every delightful triangle.
        </p>
      </div>
    </div>
  );
};

export default Production;
