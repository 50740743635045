import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./languageselect.css";
import logo from "../../assets/logo/gcmlogo-nobg.png";
import eng from "../../assets/languages/eng.png";
import fr from "../../assets/languages/fr.png";

const LanguageSelect = () => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleLanguageChange = (language) => {
    navigate(`/${language}/home`); // Navigate to the selected language home page
  };

  return (
    <div className="languageselect-container">
      <div className="gcm-logo">
        <img src={logo} alt="logo" />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="language-select">
        <div
          className="language-option language-option-en"
          onClick={() => handleLanguageChange("en")}
        >
          <span className="arrow left-arrow">&lt;</span>
          <img src={eng} alt="English" />
        </div>
        <div
          className="language-option language-option-fr"
          onClick={() => handleLanguageChange("fr")}
        >
          <img src={fr} alt="Français" />
          <span className="arrow right-arrow">&gt;</span>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelect;
