import React from "react";
import "../index.css";
import { LanguageSelect } from "../components";

const LanguagePage = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <LanguageSelect />
      </div>
    </div>
  );
};

export default LanguagePage;
