import React from "react";
import "../../components/production/production.css";
import factory from "../../assets/misc/factory.jpg";
import factory1 from "../../assets/misc/5.jpg";
import factory2 from "../../assets/misc/6.jpg";

const ProductionFr = () => {
  return (
    <div className="production-container section__padding">
      <h1>Qualite de Production</h1>
      <div className="production-image-container">
        <img
          className="production-image"
          src={factory}
          alt="Factory of Production"
        />
        <img
          className="production-image"
          src={factory1}
          alt="Factory of Production"
        />
        <img
          className="production-image"
          src={factory2}
          alt="Factory of Production"
        />
      </div>
      <div className="production-text-container">
        <p>
          Bienvenue chez Gold Cheese Morocco.
          <br />
          Chez Gold Cheese Morocco, nous sommes passionnés par la création de
          notre fromage en triangle, élaboré avec soin et précision dans notre
          usine ultramoderne. Notre engagement envers la qualité et l'excellence
          se reflète dans chaque étape de notre processus de fabrication,
          garantissant que vous profitiez d'un produit qui se distingue par son
          goût, sa texture et sa fraîcheur.
          <br />
          Située au cœur du Maroc, notre usine est équipée des dernières
          technologies et respecte les normes internationales les plus élevées.
          Dès l'arrivée du lait frais, il subit des contrôles de qualité
          rigoureux pour garantir qu'il répond à nos critères stricts. Nos
          lignes de production sont conçues pour optimiser l'efficacité tout en
          conservant la touche artisanale qui définit notre fromage. Assurance
          Qualité Chez Gold Cheese Morocco, la qualité est notre priorité
          absolue. Notre équipe d'experts veille à chaque étape de la production
          pour s'assurer que notre fromage non seulement respecte, mais dépasse
          les normes de l'industrie. Nous appliquons des protocoles d'hygiène
          stricts et surveillons en permanence nos processus pour maintenir le
          plus haut niveau d'excellence.
          <br />
          <br />
          Découvrez le goût et la qualité supérieurs de Gold Cheese Morocco, où
          la tradition rencontre l'innovation dans chaque délicieux triangle.
        </p>
      </div>
    </div>
  );
};

export default ProductionFr;
