import React from "react";
// import { Link } from "react-router-dom";
import "../../components/products/products.css";
import milky from "../../assets/product/milky-nobg.png";
import bladna from "../../assets/product/bladna-nobg.png";
import labonnevache from "../../assets/product/labonnevache-nobg.png";
import marai from "../../assets/product/marai-nobg.png";
import maraipremium from "../../assets/product/maraipremium-nobg.png";
import fromy from "../../assets/product/fromy-nobg.png";
import cheeseland from "../../assets/product/cheeseland-nobg.png";
import vitacheese from "../../assets/product/vitacheese-nobg.png";
import bladna2 from "../../assets/product/bladna3-nobg.png";
import bladna3 from "../../assets/product/bladna4-nobg.png";
import milkycream from "../../assets/product/milkycreamcheese-nobg.png";
import milkycream2 from "../../assets/product/milkycreamcheese2-nobg.png";

const ProductCard = (props) => {
  return (
    <div key={props.index} className="products-item-product-container">
      <img src={props.product.link} alt={props.product.name} />
      <div className="products-item-product-text">
        <p className="product-name">{props.product.name}</p>
        <p>{props.product.description}</p>
        {/* <p className="product-category">{props.product.category}</p> */}
      </div>
    </div>
  );
};

const ProductsFr = () => {
  const productslist = {
    processed: [
      {
        name: "Milky Cheese",
        id: "milky",
        category: "Notre propre marque",
        description:
          "Le fromage fondu MILKY en portion triangulaire est un délice réconfortant, souvent associé à des moments de partage et de convivialité. Sa texture crémeuse et son goût savoureux en font un ingrédient parfait pour agrémenter les plats ou simplement à déguster tel quel. Les portions MILKY sont idéales pour les apéritifs, se fondant merveilleusement dans des plats comme les fondues, les gratins ou même en accompagnement de légumes croquants. Leur format triangulaire facilite également la consommation, que ce soit à la maison ou lors d’un pique-nique.",
        link: milky,
        price: 0,
      },
      {
        name: "Bladna Cheese",
        id: "bladna",
        category: "Notre Propre marque",
        description:
          "Le fromage BALADNA est un véritable trésor culinaire, emblématique de la gastronomie du pays. Reconnu pour son goût riche et sa texture onctueuse, il est souvent fabriqué à partir de lait de vache. Sa méthode de production traditionnelle met en avant le savoir-faire artisanal, préservant les saveurs authentiques de la région. Baladna se distingue par son équilibre parfait entre salé et doux, offrant une expérience gustative unique. Il peut être dégusté tel quel, en apéritif, ou intégré dans divers plats locaux, ajoutant une touche savoureuse à des salades, des sandwichs ou des plats cuisinés.",
        link: bladna,
        price: 0,
      },
      {
        name: "Al Marai Shahya Cheese",
        id: "marai",
        category: "Notre Propre marque",
        description:
          "Le fromage Almarai El Shahya incarne l'alliance parfaite entre tradition et innovation. Élaboré avec un savoir-faire ancestral, chaque étape de sa production est soigneusement orchestrée pour garantir une qualité exceptionnelle. Les laiteries Almarai utilisent des techniques modernes pour assurer une traçabilité totale et une fraîcheur optimale, préservant ainsi la richesse des saveurs. La haute technologie déployée dans le processus de fabrication permet non seulement de répondre aux normes de sécurité alimentaire les plus strictes, mais aussi d'optimiser les procédés pour une efficacité accrue.",
        link: marai,
        price: 0,
      },
      {
        name: "Al Marai Premium Cheese",
        id: "maraipremium",
        category: "Notre propre Marque",
        description:
          "Le fromage Almarai El Shahya se distingue par sa texture crémeuse et son goût raffiné, faisant de chaque bouchée une expérience gustative unique. Que ce soit pour accompagner un plat ou pour être dégusté seul, il s'intègre parfaitement dans une variété de cuisines, séduisant les palais les plus exigeants. En somme, Almarai El Shahya n'est pas seulement un fromage ; c'est le résultat d'une passion pour l'excellence et d'une quête permanente d'innovation.",
        link: maraipremium,
        price: 0,
      },
    ],
    mdd: [
      {
        name: "La Bonne Vache",
        id: "labonnevache",
        category: "MDD",
        description: "",
        link: labonnevache,
        price: 0,
      },
      {
        name: "Fromy Cheese",
        id: "fromy",
        category: "MDD",
        description: "",
        link: fromy,
        price: 0,
      },
      {
        name: "CheeseLand Cheese",
        id: "cheeseland",
        category: "MDD",
        description: "",
        link: cheeseland,
        price: 0,
      },
      {
        name: "Vita Cheese",
        id: "vitacheese",
        category: "MDD",
        description: "",
        link: vitacheese,
        price: 0,
      },
    ],
    spreadables: [
      {
        name: "Milky Cream Cheese 2Kg",
        id: "milkycreamcheese",
        category: "Notre propre marque",
        description:
          "Profitez du goût riche et crémeux du fromage à la crème Milky ! Parfait pour tartiner sur des bagels, préparer de délicieux cheesecakes ou réaliser des dips savoureux. Notre pot de 2 kg est pratique pour un usage domestique ou en restauration. Élevez vos repas avec le fromage à la crème Milky dès aujourd'hui !",
        link: milkycream,
        price: 0,
      },
      {
        name: "Milky Cream Cheese 250g",
        id: "milkycreamcheese2",
        category: "Notre propre marque",
        description:
          "Découvrez le goût délicieux du fromage à la crème Milky dans un format pratique de 250 g ! Idéal pour tartiner sur des toasts, l'ajouter à des recettes ou le déguster directement du pot. Parfait pour les repas légers et les collations, le fromage à la crème Milky est votre allié pour une onctuosité à tout moment !",
        link: milkycream2,
        price: 0,
      },
      {
        name: "Bladna",
        id: "bladna2",
        category: "Notre propre marque",
        description:
          "Régalez-vous avec la douceur et la crémeux du fromage à la crème Bladna ! Parfaitement équilibré en saveurs, il est idéal pour tartiner sur votre pain préféré, rehausser vos recettes ou créer des dips savoureux. Disponible dans un format pratique, Bladna apporte une touche de richesse à chaque repas. Élevez vos collations et votre cuisine avec le fromage à la crème Bladna !",
        link: bladna2,
        price: 0,
      },
    ],
    pizzacheese: [
      {
        name: "Bladna",
        id: "bladna3",
        category: "Notre propre marque",
        description:
          "Découvrez Bladna, le bloc de fromage ultime pour les amateurs de pizza ! Fabriqué à partir des meilleurs ingrédients, Bladna offre une texture riche et crémeuse qui fond à merveille, garantissant que chaque part est un délice fromager. Parfait pour les pizzas traditionnelles ou des garnitures innovantes, ce bloc de fromage polyvalent rehausse le profil de saveur de toute pizza, créant une expérience savoureuse qui vous fera revenir à la charge. Élevez votre soirée pizza avec Bladna, où la qualité rencontre l'indulgence !",
        link: bladna3,
        price: 0,
      },
    ],
  };
  return (
    <div className="products section__padding section">
      <div className="products-title-container">
        <h1 className="products-title">Produits</h1>
      </div>
      {/* Produit Tartine */}
      <div className="products-item-container">
        <h1>Fromages triangulaire en portions</h1> <br />
        <h1>Nos Propres Marques</h1>
        {productslist.processed.map((product, index) => (
          // <Link to={"/en/products/" + product.id}>
          <ProductCard product={product} index={index} />
          // </Link>
        ))}
        <h1>Marques Distributeurs (MDD)</h1>
        {productslist.mdd.map((product, index) => (
          // <Link to={"/en/products/" + product.id}>
          <ProductCard product={product} index={index} />
          // </Link>
        ))}
        <h1>Fromages à tartiner et Cream Cheese</h1> <br />
        {productslist.spreadables.map((product, index) => (
          // <Link to={"/en/products/" + product.id}>
          <ProductCard product={product} index={index} />
          // </Link>
        ))}
        <h1>Fromages Bloc</h1> <br />
        {productslist.pizzacheese.map((product, index) => (
          // <Link to={"/en/products/" + product.id}>
          <ProductCard product={product} index={index} />
          // </Link>
        ))}
      </div>
    </div>
  );
};

export default ProductsFr;
