import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import eng from "../../assets/languages/eng.png";
import fr from "../../assets/languages/fr.png";
import logo from "../../assets/logo/gcmlogo-nobg.png";
import "../../components/navbar/navbar.css";

const NavbarFr = ({ language }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  // Define base paths based on the selected language
  const basePath = language === "fr" ? "/fr" : "/en";

  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleLanguageChange = (language) => {
    navigate(`/${language}/home`); // Navigate to the selected language home page
  };

  return (
    <div className="gcm__navbar">
      <div className="gcm__navbar-links">
        <div className="gcm__navbar-links_logo">
          <img className="gcmlogo" src={logo} alt="logo" />
        </div>
        <div className="gcm__navbar-links_container">
          <p>
            <Link to={`${basePath}/home`}>Accueil</Link>
          </p>
          <p>
            <Link to={`${basePath}/products`}>Produits</Link>
          </p>
          <p>
            <Link to={`${basePath}/services`}>Services</Link>
          </p>
          <p>
            <Link to={`${basePath}/partners`}>Clients</Link>
          </p>
          <p>
            <Link to={`${basePath}/contact`}>Contact</Link>
          </p>
          <p>
            <Link to={`${basePath}/about`}>Nous</Link>
          </p>
        </div>
        <div className="gcm__navbar-sign navbar-language-selector">
          {/* <p className="language-selector-lang">
            <Link to="/fr/home">Fr </Link>
          </p>
          <p className="language-selector-lang">
            <Link to="/en/home">En</Link>
          </p> */}
          <Link to="/en/home">
            <img
              src={eng}
              alt="English"
              onClick={() => handleLanguageChange("en")}
              className="language-selector-lang"
            />
          </Link>
          <Link to="/fr/home">
            <img
              src={fr}
              alt="Francais"
              onClick={() => handleLanguageChange("fr")}
              className="language-selector-lang"
            />
          </Link>
        </div>
      </div>

      <div className="gcm__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            className="touch-logo"
            color="#000"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            className="touch-logo"
            color="#000"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gcm__navbar-menu_container scale-up-center">
            <div className="gcm_navbar-menu_container-links">
              <p>
                <Link to={`${basePath}/home`}>Accueil</Link>
              </p>
              <p>
                <Link to={`${basePath}/products`}>Produits</Link>
              </p>
              <p>
                <Link to={`${basePath}/services`}>Services</Link>
              </p>
              <p>
                <Link to={`${basePath}/partners`}>Partenaires</Link>
              </p>
              <p>
                <Link to={`${basePath}/contact`}>Contact</Link>
              </p>
              <p>
                <Link to={`${basePath}/about`}>A propos</Link>
              </p>
            </div>
            <div className="gcm__navbar-menu_container-links-sign language-selector">
              <p className="language-selector-lang-sign">
                <Link to="/fr/home">Francais</Link>
              </p>
              <p className="language-selector-lang-sign">
                <Link to="/en/home">Anglais</Link>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarFr;
