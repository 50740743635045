import React from "react";
import { Navbar, Slideshow, Footer } from "../../components";
import ProductInfo from "../../components/productinfo/ProductInfo";
import milky from "../../assets/products/milky.JPG";

const MilkyPage = () => {
  const milkyproduct = {
    name: "Milky Cheese",
    id: "milky",
    category: "Fromage a Tartiner",
    description: "",
    link: milky,
    price: 0,
  };
  return (
    <div>
      <Navbar language="en" />
      <Slideshow />
      <ProductInfo product={milkyproduct} />
      <Footer />
    </div>
  );
};

export default MilkyPage;
