import React from "react";
import LogoSlideshow from "../logoslideshow/LogoSlideshow";
import "./clients.css";

const Clients = () => {
  return (
    <div className="client-container">
      <LogoSlideshow />
      <h1>"Our Clients chose to work with the best."</h1>
      <p>
        Our Clients have consistently chosen to work with us due to our
        unwavering commitment to quality and authenticity. Our deep
        understanding of the local cheese-making traditions, combined with
        modern production techniques, ensures that we deliver exceptional
        products that meet international standards. They appreciate our
        transparent communication and personalized service, which fosters a
        strong sense of partnership. This trust is further solidified by our
        timely deliveries and the positive feedback from their customers,
        reinforcing our reputation as a reliable supplier in the gourmet cheese
        market.
      </p>
    </div>
  );
};

export default Clients;
