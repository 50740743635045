import React from "react";
import "../../components/services/services.css";
import familledeproduit1 from "../../assets/products/familleproduit1.jpg";
import familledeproduit2 from "../../assets/products/familleproduit2.jpg";
import familledeproduit3 from "../../assets/products/familleproduit3.jpg";

const ServicesFr = () => {
  const productlist = [
    {
      name: familledeproduit1,
      link: familledeproduit1,
    },
    {
      name: familledeproduit2,
      link: familledeproduit2,
    },
    {
      name: familledeproduit3,
      link: familledeproduit3,
    },
  ];
  return (
    <div className="service-container section__padding section">
      <h1>Services</h1>
      <div className="images">
        {productlist.map((product, index) => (
          <img src={product.link} key={index} alt={product.name} />
        ))}
      </div>
      <div className="services-description ">
        <p>
          Service Fabrication et distribution de Fromages et de preparations
          laitieres pour les :
        </p>
        <ul className="section__padding">
          <li>Societe depositaire</li>
          <li>Distributeurs</li>
          <li>Grossistese</li>
          <li>Epiceries</li>
          <li>Mahlabat</li>
        </ul>
        <p>Fabrication de produits de marque distributeurs (MDD) :</p>
        <p>
          Grandes societies marocaines renommees specialisees dans la
          fabrication et distribution des produits agro-alimentaires :
        </p>
        <ul>
          <li>Boucherons (Cheeseland)</li>
          <li>FGD (La Bonne Vache) - Groupe Dalaa</li>
          <li>Dispram Groupe FARMALAC (Rayna)</li>
          <li>Gastro Mixte (Al Marai Al Shahya)</li>
          <li>Fromagerie Atlas (Fromy)</li>
          <li>Vitablend (Vitacheese)</li>
          <li>Top Food Products (Milky - Al Marai - Bladna)</li>
        </ul>
      </div>
    </div>
  );
};

export default ServicesFr;
