import React from "react";
import LogoSlideshow from "../../components/logoslideshow/LogoSlideshow.jsx";
import "../../components/clients/clients.css";

const ClientsFr = () => {
  return (
    <div className="client-container">
      <LogoSlideshow />
      <h1>"Nos clients choisissent de travailler avec les meilleurs."</h1>
      <p>
        Nos clients ont constamment choisi de travailler avec nous en raison de
        notre engagement indéfectible envers la qualité et l'authenticité. Notre
        profonde compréhension des traditions locales de fabrication de fromage,
        combinée à des techniques de production modernes, nous permet de livrer
        des produits exceptionnels conformes aux normes internationales. Ils
        apprécient notre communication transparente et notre service
        personnalisé, qui favorisent un fort esprit de partenariat. Cette
        confiance est renforcée par nos livraisons ponctuelles et les retours
        positifs de leurs clients, consolidant ainsi notre réputation en tant
        que fournisseur fiable sur le marché des fromages gourmets.
      </p>
    </div>
  );
};

export default ClientsFr;
