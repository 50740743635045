import React from "react";
import "./about.css";

const About = () => {
  return (
    <div className="info section__padding">
      <h1>About Us</h1>

      <h3>Who are we ?</h3>
      <div className="identity">
        <p>
          Founded in Lybia in 2005, Tamah Food Industries a company dedicated
          for the Import and Distribution of Food Products established its first
          factory for the production of all kinds of cheese, with a cooking
          capacity of 2 tons per hour, using high-quality, user-friendly
          equipment.
        </p>
      </div>
      <p>
        In 2020, Tamah launched its cheese manufacturing subsidiary in Morocco
        under the name of GOLD CHEESE MOROCCO, which achieved great success by
        introducing its own brands: MARAI EL SHAHIA, MILKY, and BALADNA. It also
        became the first company to manufacture private label products,
        collaborating with major Moroccan companies to produce their own brands.
      </p>
    </div>
  );
};

export default About;
