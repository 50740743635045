import React from "react";
import "../../index.css";
import { Navbar, Footer, Services } from "../../components";

const ServicesPage = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar className="navbar" language="en" />
        <Services />
        <Footer />
      </div>
    </div>
  );
};

export default ServicesPage;
