import React from "react";
import "../../components/about/about.css";

const AboutFr = () => {
  return (
    <div className="info section__padding">
      <h1>À propos de nous</h1>

      <h3>Qui sommes-nous ?</h3>
      <div className="identity">
        <p>
          Fondée (Tamah Food Industries) en 2005, née (société Tamah pour
          l'importation et la distribution de produits alimentaires), elle a
          créé la première usine de l'entreprise pour la fabrication de toutes
          sortes de fromages, cuite avec une capacité de production de 2 tonnes
          par heure, conviviale et de haute qualité. matériel de qualité.
        </p>
      </div>
      <p>
        En 2018 Tamah a lancer sa filiale de fabrication de fromage au Maroc
        sous le nom de GOLD CHEESE MOROCCO qui a connu un grand succes soit par
        le lancement de ses propres marques MARAI EL SHAHIA , MILKY et BALADNA
        soit comme la premiere societe a fabriquer des marque de distributeur ou
        elle travailler avec des grand societe marocaine en leur fabricant leurs
        propres marques.
      </p>
    </div>
  );
};

export default AboutFr;
