import React from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";
import eng from "../../assets/languages/eng.png";
import fr from "../../assets/languages/fr.png";
import logo from "../../assets/logo/gcmlogo-nobg.png";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/linkedin";
import "react-social-icons/facebook";

const Footer = () => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleLanguageChange = (language) => {
    navigate(`/${language}/home`); // Navigate to the selected language home page
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt="Company Logo" className="logo-image" />
          <p className="footer-location">
            Zone Industrielle Dar Bouazza Lot N 14 - Casablanca
          </p>
          <p className="footer-contact">Phone: +212 5 22 96 14 71</p>
          <p className="footer-contact">Email: info@goldcheesemorocco.com</p>
          <div className="icons">
            <SocialIcon
              className="icon"
              url="https://ma.linkedin.com/company/goldcheesemorocco"
            />
            <SocialIcon
              className="icon"
              url="https://www.facebook.com/p/Gold-Cheese-Morocco-100063671060599/?locale=fr_FR"
            />
            <div className="footer-language-select">
              <img
                src={eng}
                alt="English"
                onClick={() => handleLanguageChange("en")}
              />
              <img
                src={fr}
                alt="Francais"
                onClick={() => handleLanguageChange("fr")}
              />
            </div>
          </div>
        </div>
        <div className="footer-info map-container">
          <iframe
            title="goldcheesemorocco-location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212947.49679086782!2d-7.901453534277832!3d33.493450419221695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda881000cbc907f%3A0x1ce82c54df7435e8!2sGold%20Cheese%20Morocco!5e0!3m2!1sfr!2sma!4v1725797236866!5m2!1sfr!2sma"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="footer-map"
          ></iframe>
        </div>
        <br />
        <div className="line" />
        <div className="footer-copyright">
          <p>
            Gold Cheese Morocco 2024, Created by <b>Marketing Services</b> -
            GOLD CHEESE MOROCCO. All rights Reserved to Gold Cheese Morocco ©.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
