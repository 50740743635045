import React from "react";
import "./services.css";
import familledeproduit1 from "../../assets/product/familleproduit1.jpg";
import familledeproduit2 from "../../assets/product/familleproduit2.jpg";
import familledeproduit3 from "../../assets/product/familleproduit3.jpg";

const Services = () => {
  const productlist = [
    {
      name: familledeproduit1,
      link: familledeproduit1,
    },
    {
      name: familledeproduit2,
      link: familledeproduit2,
    },
    {
      name: familledeproduit3,
      link: familledeproduit3,
    },
  ];
  return (
    <div className="service-container section__padding section">
      <h1>Services</h1>
      <div className="images">
        {productlist.map((product, index) => (
          <img src={product.link} key={index} alt={product.name} />
        ))}
      </div>
      <div className="services-description ">
        <p>
          Manifacturing and distribution of cheese products and dairy
          preparations for:
        </p>
        <ul className="">
          <li>Depository company.</li>
          <li>Distributors.</li>
          <li>Wholesalers.</li>
          <li>Grocery stores</li>
          <li>Moroccan Mahlabat</li>
        </ul>
        <p>Manufacturing of private label products (MDD):</p>
        <p>
          Major renowned Moroccan companies specializing in the manufacturing
          and distribution of agri-food products:
        </p>
        <ul>
          <li>Boucherons (Cheeseland)</li>
          <li>FGD (La Bonne Vache) - Groupe Dalaa</li>
          <li>Dispram Groupe FARMALAC (Rayna)</li>
          <li>Gastro Mixte (Al Marai Al Shahya)</li>
          <li>Fromagerie Atlas (Fromy)</li>
          <li>Vitablend (Vitacheese)</li>
          <li>Top Food Products (Milky - Al Marai - Bladna)</li>
        </ul>
      </div>
    </div>
  );
};

export default Services;
